import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [];

export const dictionary = {
		"/": [23],
		"/94d192b3a326be1f": [~24,[2],[3]],
		"/__/auth/handler": [25,[4]],
		"/__/auth/iframe": [26,[5]],
		"/checkout": [~29,[10],[11]],
		"/checkout/invoice": [~30,[10],[11]],
		"/contact": [31,[12]],
		"/c/[channel_id]": [27,[6],[7]],
		"/c/[channel_id]/f/[feed_id]": [~28,[6,8],[7,9]],
		"/feeds/[feed_id]": [~32,[13],[14]],
		"/login": [33],
		"/new": [34,[15]],
		"/privacy-policy": [35,[16]],
		"/scta": [36,[17]],
		"/settings": [37,[18]],
		"/settings/earnings": [~38,[18,19]],
		"/supporting": [~39,[20],[21]],
		"/tos": [40,[22]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';